@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Futura';
  font-weight: 500;
  src: url('../assets/font/futura-bold-webfont.woff');
  font-display: swap;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  scroll-padding-top: 200px;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth !important;
}

#launcher-frame {
  min-width: 400px !important;
  max-width: 400px !important;
}

#launcher-frame > .launcher-button .elements__Wrapper-sc-4gy1pz-0 .blzvQB {
  float: left;
}
